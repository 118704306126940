import "./Contact.css";

function Contact() {
  return (
    <div className="Contact">
      <h1 className="font-bold text-center text-black text-bold text-4xl">
        Contact
      </h1>
    </div>
  );
}

export default Contact;
