import "./ProjectCard.css";

function ProjectCard(props) {
  return (
    <div className="ProjectCard">
      <h1 className="text-3xl">{props.title}</h1>
      <h1 className="text-xl">{props.text}</h1>
    </div>
  );
}

export default ProjectCard;
