import "./SocialLink.css";

function SocialLink(props) {
  return (
    <a
      className="social-link-container"
      href={props.link}
      target="_blank"
      rel="noreferrer"
    >
      {props.icon}
      <h1>{props.name}</h1>
    </a>
  );
}

export default SocialLink;
