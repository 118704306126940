import "./Projects.css";
import ProjectCard from "./ProjectCard";

function Projects() {
  let projects = [];
  projects.push({ title: "asdf", text: "This is the " });
  projects.push(2);
  projects.push(3);

  return (
    <div className="Projects">
      <h1 className="font-bold text-center text-black text-bold text-4xl">
        Projects
      </h1>
      <div className="Project-container mt-8">
        {projects.map((data) => {
          return <ProjectCard title={data.title} text={data.text} />;
        })}
      </div>
    </div>
  );
}

export default Projects;
