import { Routes, Route } from "react-router-dom";

import "./App.css";
import Contact from "./components/Contact";
import NavBar from "./components/NavBar";
import About from "./components/About";
import Projects from "./components/Projects";
import Home from "./components/Home";

function App() {
  let primaryText = "Primary";
  let secondaryText = "Secondary ";
  return (
    <div className="App">
      <NavBar />
      <div className="App-main">
        <div id="home">
          <Home />
        </div>
        <div id="about">
          <About
            title="About"
            primary={primaryText}
            secondary={secondaryText}
          />
        </div>
        <div id="projects">
          <Projects />
        </div>
        <div id="contact">
          <Contact />
        </div>
      </div>
    </div>
  );
}

export default App;
