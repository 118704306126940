import "./NavBar.css";

import { Link, animateScroll as scroll } from "react-scroll";

function NavBar() {
  return (
    <div className="NavBar">
      <div className="NavLink">
        <Link
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
          offset={-80}
          duration={100}
          className="link"
        >
          Home
        </Link>
      </div>
      <div className="NavLink">
        <Link
          activeClass="active"
          to="about"
          spy={true}
          smooth={true}
          offset={-80}
          duration={100}
          className="link"
        >
          About
        </Link>
      </div>
      <div className="NavLink">
        <Link
          activeClass="active"
          to="projects"
          spy={true}
          smooth={true}
          offset={-80}
          duration={100}
          className="link"
        >
          Projects
        </Link>
      </div>
      <div className="NavLink">
        <Link
          activeClass="active"
          to="contact"
          spy={true}
          smooth={true}
          offset={-80}
          duration={100}
          className="link"
        >
          Contact
        </Link>
      </div>
    </div>
  );
}

export default NavBar;
