import React from "react";
import "./About.css";
import Collapsible from "react-collapsible";
import { useState } from "react";

// bg-gradient-to-r from-rose-400 via-fuchsia-500 to-indigo-500

const DropdownLabel = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="flex flex-row">
      <h1>{props.title}</h1>
      <h1>{">"}</h1>
    </div>
  );
};

const About = (props) => {
  return (
    <div className="About">
      <h1 className="font-bold text-center text-black text-bold text-4xl">
        About Me
      </h1>
      <div className="flex flex-row gap-40">
        <div className="w-[500px] text-left flex flex-col gap-4">
          <p>
            I am a sophomore (class of 2026) at MIT majoring in computer science
            and mathematics. I am interested in a myriad of subjects in computer
            science, ranging from deep learning to web development.
          </p>
          <p>
            In my free time, I enjoy playing tennis, watching Christopher Nolan
            movies, and picking up new hobbies.
          </p>
          <a
            href="https://acyjiang.com/resume.pdf"
            target="_blank"
            className="Resume"
          >
            Resume
          </a>
        </div>

        {/* create a list of coursework grouped by year. Each year has a list of bullet points, and you can collapse or open the list for each year by clicking a button.*/}
        <div className="text-left">
          <h1 className="text-3xl">Coursework</h1>

          <Collapsible trigger={<DropdownLabel title="Previous" />}>
            <div>
              <li>
                6.100A (6.0001) Introduction to Computer Science Programming in
                Python
              </li>
              <li>18.01 Single Variable Calculus</li>
              <li>18.02 Multivariable Calculus</li>
            </div>
          </Collapsible>

          <Collapsible trigger={<DropdownLabel title="Fall 2022" />}>
            <div>
              <li>6.101 (6.009): Fundamentals of Programming</li>
              <li>6.1210 (6.006): Introduction to Algorithms</li>
              <li>
                6.190 (6.0004): Introduction to Low-level Programming in C and
                Assembly
              </li>
              <li>18.701: Algebra I</li>
              <li>5.111: Principles of Chemical Science</li>
            </div>
          </Collapsible>

          <Collapsible trigger={<DropdownLabel title="Spring 2023" />}>
            <div>
              <li>6.390 (6.036): Introduction to Machine Learning</li>
              <li>6.1220 (6.046): Design and Analysis of Algorithms</li>
              <li>6.191 (6.004): Computation Structures</li>
              <li>18.600: Probability and Random Variables</li>
              <li>24.05: Philisophy of Religion</li>
            </div>
          </Collapsible>

          <Collapsible trigger={<DropdownLabel title="Fall 2023" />}>
            <div>
              <li>
                6.8611: Quantitative Methods for Natural Language Processing
              </li>
              <li>18.650: Statistics for Applications</li>
              <li>14.03: Microeconomic Theory and Public Policy</li>
              <li>14.73: The Challenge of World Poverty</li>
            </div>
          </Collapsible>
        </div>
      </div>
    </div>
  );
};

export default About;
